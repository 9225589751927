





































































































































::v-deep .set-container {
    margin: 20px;
    background: #fff;
    height: calc(100% - 40px);
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    & > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.info-box {
    padding: 0 20px 20px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -40px;
    margin-top: -20px;
    .info-left {
        width: calc(50% - 40px);
        margin-left: 40px;
    }
    .info-right {
        width: calc(50% - 40px);
        margin-left: 40px;
    }
}
.info-item {
    margin-top: 40px;
    .info-title {
        /*color: #409eff;*/
        line-height: 40px;
        font-size: 18px;
        text-align: center;
        font-weight: 500;
        position: relative;
        margin-bottom: 10px;
        span {
            background: #fff;
            padding: 0 20px;
            z-index: 1;
            position: relative;
        }
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            height: 1px;
            background: #e6e6e6;
            transform: translateY(-50%);
            z-index: 0;
        }
    }
    .info-detail {
        .line {
            display: flex;
            .line-item {
                width: 1%;
                flex: 1;
                margin-left: 20px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }
        .line-item {
            display: flex;
            border: 1px solid #e6e6e6;
            margin-bottom: 20px;
            .line-text {
                width: 80px;
                padding: 10px;
                box-sizing: border-box;
                background: #f6f6f6;
                word-break: break-all;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .line-detail {
                width: 1%;
                flex: 1;
                padding: 10px 20px;
                box-sizing: border-box;
                border-left: 1px solid #e6e6e6;
                word-break: break-all;
                display: flex;
                align-items: center;
            }
            .line-unit {
                padding: 10px 5px;
                background: #f6f6f6;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                border-left: 1px solid #e6e6e6;
            }
        }
    }
}
.no-data {
    text-align: center;
    font-size: 16px;
}
.logistics-box {
    height: 400px;
    ::v-deep & > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .logistics-item {
        margin-bottom: 10px;
        .log-line {
            color: #666;
            display: flex;
            align-items: flex-end;
            margin-bottom: 4px;
            .status {
                color: #333;
                font-size: 15px;
                margin-right: 6px;
                font-weight: 500;
            }
        }
    }
}
